.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Start{
  background-color: rgb(233, 238, 207);
  width: 100vw;
  height: 100vh;
}

.CountDown{
  background-color: rgb(0, 0, 0);
  color: rgb(255, 187, 0);
  text-align: center;
  font-size: 30vh;
  height: 100vh;
}

.Play{
  background-color: blue;
  display:flex;
  flex-flow: column;
}
.PlayInfo{
  background-color: rgb(0, 0, 0);
  text-align: center;
  font-size: 4vh;
  color: antiquewhite;
  height: 5vh;
}
.PlayRow{
  background-color: blueviolet;
  display:flex;

}
.PlayColumn{
  background-color: rgb(44, 4, 4);
  border-style: solid;
  border-width: 5px;
  border-color: black;
  width: 33.3vw;
  height: 30vh;
}
.PlayColumnActiveOne{
  background-color: rgb(255, 0, 0);
}
.PlayColumnActiveTwo{
  background-color: rgb(0, 4, 255);
}
.PlayColumnDisable{
  background-color: rgb(44, 4, 4);
}
.DamashiColumn{
  background-color: rgb(255, 255, 255);
  text-align: center;
  font-size: 20vh;
}
.ActiveColorOne{
  color: rgb(255, 0, 0);
  margin: 0vh;
}
.ActiveColorTwo{
  color: rgb(0, 4, 255);
  margin: 0vh;
}

